import React from "react";
import { parasPrintDetails, parassolutionsDetails } from "../constant";

const CompanyDetails = ({ selectedCompany, setSelectedCompany }) => {
  const companies = {
    parasSolutions: parassolutionsDetails,
    parasPrint: parasPrintDetails,
  };

  const handleChange = (e) => {
    setSelectedCompany(e.target.value);
  };

  const selectedCompanyDetails = companies[selectedCompany];

  const { clientCompanyName, ...details } = selectedCompanyDetails;

  return (
    <header className="flex ml-8 mt-5 mr-8">
      <div className="w-[70%] border border-black">
        <div className="p-4">
          <select
            className="appearance-none hover:appearance-auto rounded text-xl pb-1 pr-2 focus:border focus:bg-slate-200 focus:border-black font-semibold leading-tight focus:outline-none hover:bg-slate-200"
            value={selectedCompany}
            onChange={handleChange}
          >
            <option value="parasPrint">PARAS PRINT</option>
            <option value="parasSolutions">PARAS SOLUTIONS</option>
          </select>
          {Object.values(details).map((detail, index) => (
            <p key={index} className="text-sm">
              {detail}
            </p>
          ))}
        </div>
      </div>
      <div className="w-[30%] border-r border-t border-b border-black">
        <div className="pl-8 mt-[80px]">
          <h3 className="text-2xl font-bold text-slate-600">TAX - INVOICE</h3>
          <h3 className="pl-8 text-2xl font-bold text-slate-600">(Original)</h3>
        </div>
      </div>
    </header>
  );
};

export default CompanyDetails;
