import React, { useState } from "react";

export default function TdsLine() {
  const [tdsDes, setTdsDes] = useState(
    "NOTE: TDS Not applicable on postal reimbursement\ncharges"
  );

  return (
    <section className="flex ml-8 mr-8 border-r border-b border-black">
      <div className="text-sm pt-10 font-bold border-l border-r border-black p-2 w-[50%]">
        <textarea
          className="text-wrap"
          value={tdsDes}
          onChange={(e) => setTdsDes(e.target.value)}
          style={{ width: "100%", resize: "none" }}
        />
      </div>
      <div className="text-sm text-center font-medium border-r border-black p-2 w-[10%]"></div>
      <div className="text-sm text-center font-medium border-r border-black p-2 w-[10%]"></div>
      <div className="text-sm font-medium border-r border-black p-2 w-[12%] text-center"></div>
      <div className="text-sm font-medium border-black p-2 text-center pl-10"></div>
    </section>
  );
}
