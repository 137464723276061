import { useEffect, useRef, useState } from "react";
import CompanyDetails from "./components/CompanyDetails";
import ReactToPrint from "react-to-print";
import ClientDetails from "./components/ClientDetails";
import EditableCalender from "./components/EditableCalender";
import DescriptionHead from "./components/DescriptionHead";
import Description from "./components/Description";
import EditDescription from "./components/EditDescription";
import ConvertToWords from "./components/ConvertToWords";
import { v4 as uuidv4 } from "uuid";
import AmountDetails from "./components/AmountDetails";
import Footer from "./components/Footer";
import TdsLine from "./components/TdsLine";
import HrDescription from "./components/HrDescription";

function App() {
  // Date Details
  const [invoiceNo, setInvoiceNo] = useState("0 / 2024-25");
  const [invoiceDate, setInvoiceDate] = useState();
  const [challan, setChallan] = useState();
  const [dueDate, setDueDate] = useState(); // for parasSolutions
  const [dueDateText, setDueDateText] = useState("30 Days"); // for parasPrint
  const [invoiceNoLabel, setInvoiceNoLabel] = useState("Invoice-No:");
  const [invoiceDateLabel, setInvoiceDateLabel] = useState("Invoice-Date:");
  const [challanLabel, setChallanLabel] = useState("CHALLAN:");
  const [dueDateLabel, setDueDateLabel] = useState("Due-Date:");

  // Description Row
  const [description, setDescription] = useState("");
  const [selectDescription, setSelectDescription] = useState("");
  const [selectHsnCode, setSelectHsnCode] = useState("");
  const [qty, setQty] = useState(0);
  const [selectUnitPrice, setSelectUnitPrice] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [fuelSurcharge, setFuelSurcharge] = useState(0);
  const [totalGst, setTotalGst] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [inWords, setInWords] = useState("");
  const [desItem, setDesItem] = useState([]);
  const [finalAmountAfterGst, setFinalAmountAfterGst] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [labels, setLabels] = useState({
    descriptionLabel: "Description:",
    hsnCodeLabel: "HSN CODE:",
    qtyLabel: "QTY",
    unitPriceLabel: "Unit Price:",
    totalLabel: "Total:",
    totalAmountBeforeGSTLabel: "Amount Before TAX:",
    sgstLabel: "SGST (9%)",
    cgstLabel: "CGST (9%)",
    fuelSurchargeLabel: "Fuel Surcharge (10%)",
    totalGstLabel: "Total Amount: GST",
    totalAmountAfterGSTLabel: "Total Amount After GST:",
    totalAmountInWordsLabel: "AMOUNT CHARGABLE (in words):",
  });

  const [showCalculateBox, setShowCalculateBox] = useState(false);
  const [showDateBox, setShowDateBox] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("parasPrint");
  const [selectedClient, setSelectedClient] = useState("piramalEnterprises");
  const [selectedBankDetails, setSelectedBankDetails] =
    useState("parasSolutionsBank");

  const componentRef = useRef();

  function handleDateBox() {
    setShowDateBox(!showDateBox);
    if (!showDateBox) {
      setShowCalculateBox(false);
    }
  }

  function handleCalculateBox() {
    setShowCalculateBox(!showCalculateBox);
    if (!showCalculateBox) {
      setShowDateBox(false);
    }
  }

  function handleDescriptionRow(e) {
    if (!qty) return;

    e.preventDefault();

    const newDesLine = {
      id: uuidv4(),
      selectHsnCode,
      description,
      selectDescription,
      qty,
      selectUnitPrice,
      unitPrice,
      total,
    };

    setDesItem([...desItem, newDesLine]);

    setQty(0);
    setUnitPrice(0);
    setSelectHsnCode("");
    setDescription("");
    setSelectDescription("Post Reimnursement Charges");
    setUnitPrice(0);
    setSelectUnitPrice(0);
    setTotal(0);
  }

  // Calculate total amount and set it in state
  useEffect(() => {
    const calculateTotalAmount = () => {
      const total = desItem.reduce((acc, item) => {
        if (item.selectDescription === "Courier Charges") {
          // For "Courier Charges", use the total directly
          return acc + parseFloat(item.total);
        } else {
          // For other items, calculate the total based on qty * unitPrice
          const itemTotal =
            item.qty *
            (item.unitPrice !== 0
              ? parseFloat(item.unitPrice)
              : parseFloat(item.selectUnitPrice));
          return acc + itemTotal;
        }
      }, 0);
      setTotalAmount(parseFloat(total.toFixed(2)));
    };

    calculateTotalAmount();
  }, [desItem]);

  useEffect(() => {
    let calculatedTotal = 0;

    if (selectUnitPrice === "Edit") {
      calculatedTotal = parseFloat((qty * unitPrice).toFixed(2));
    } else if (selectDescription === "Courier Charges") {
      calculatedTotal = total;
    } else {
      calculatedTotal = parseFloat((qty * selectUnitPrice).toFixed(2));
    }

    setTotal(calculatedTotal);

    const extractTaxRate = (label) => {
      const match = label.match(/(\d+)%/);
      return match ? parseFloat(match[1]) : 0;
    };

    const sgstTaxRate = extractTaxRate(labels.sgstLabel);
    const cgstTaxRate = extractTaxRate(labels.cgstLabel);
    const fuelSurchargeRate = extractTaxRate(labels.fuelSurchargeLabel);

    const calculateTax = (taxRate) =>
      totalAmount ? (totalAmount * taxRate) / 100 : 0;

    setSgst(parseFloat(calculateTax(sgstTaxRate).toFixed(2)));
    setCgst(parseFloat(calculateTax(cgstTaxRate).toFixed(2)));
    setFuelSurcharge(parseFloat(calculateTax(fuelSurchargeRate).toFixed(2)));
  }, [
    qty,
    unitPrice,
    selectUnitPrice,
    labels.sgstLabel,
    labels.cgstLabel,
    labels.fuelSurchargeLabel,
    totalAmount,
    selectDescription,
  ]);

  useEffect(() => {
    const extractTaxRate = (label) => {
      const match = label.match(/(\d+)%/);
      return match ? parseFloat(match[1]) : 0;
    };

    const sgstTaxRate = extractTaxRate(labels.sgstLabel);
    const cgstTaxRate = extractTaxRate(labels.cgstLabel);
    const fuelSurchargeRate = extractTaxRate(labels.fuelSurchargeLabel);

    const calculateTax = (taxRate) =>
      totalAmount ? (totalAmount * taxRate) / 100 : 0;

    setSgst(parseFloat(calculateTax(sgstTaxRate).toFixed(2)));
    setCgst(parseFloat(calculateTax(cgstTaxRate).toFixed(2)));
    setFuelSurcharge(parseFloat(calculateTax(fuelSurchargeRate).toFixed(2)));
  }, [
    totalAmount,
    labels.sgstLabel,
    labels.cgstLabel,
    labels.fuelSurchargeLabel,
  ]);

  // Calculate total GST
  useEffect(() => {
    setTotalGst(parseFloat((sgst + cgst).toFixed(2)));
  }, [sgst, cgst]);

  // Calculate final amount after GST
  useEffect(() => {
    let finalAmount;
    if (
      selectedCompany === "parasPrint" &&
      desItem[0]?.selectDescription === "Courier Charges"
    ) {
      finalAmount = parseFloat(
        (totalAmount + sgst + cgst + fuelSurcharge).toFixed(2)
      );
    } else {
      finalAmount = parseFloat((totalAmount + totalGst).toFixed(2));
    }
    setFinalAmountAfterGst(finalAmount);
  }, [
    selectedCompany,
    desItem,
    totalAmount,
    totalGst,
    sgst,
    cgst,
    fuelSurcharge,
  ]);

  useEffect(() => {
    const convertInWords = ConvertToWords(finalAmountAfterGst);
    setInWords(convertInWords);
  }, [finalAmountAfterGst]);

  function handleDelete(id) {
    setDesItem(desItem.filter((item) => item.id !== id));
  }

  function handleEdit(id) {
    const editingRow = desItem.find((item) => item.id === id);
    setDesItem(desItem.filter((item) => item.id !== id));
    setIsEditing(true);
    setShowCalculateBox(true);
    setSelectDescription(editingRow.selectDescription);
    setDescription(editingRow.description);
    setSelectHsnCode(editingRow.selectHsnCode);
    setQty(editingRow.qty);
    setUnitPrice(editingRow.unitPrice);
    setSelectUnitPrice(editingRow.selectUnitPrice);
    setTotal(editingRow.total);
  }

  // Function to format date as dd-mm-yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const today = new Date();
  const date = formatDate(today);

  const [formattedInvoiceDate, setFormattedInvoiceDate] = useState(
    invoiceDate ? formatDate(invoiceDate) : date
  );

  const [formattedDueDate, setFormattedDueDate] = useState(
    invoiceDate ? formatDate(invoiceDate) : ""
  );

  // Function to handle changes in invoice date
  const handleInvoiceDateChange = (e) => {
    const formattedDate = formatDate(e.target.value);
    setFormattedInvoiceDate(formattedDate);
  };

  const handleDueDateChange = (e) => {
    const formattedDate = formatDate(e.target.value);
    setFormattedDueDate(formattedDate);
  };

  useEffect(() => {
    if (selectedCompany === "parasPrint") {
      setSelectedClient("piramalEnterprises");
      setSelectedBankDetails("parasPrintBank");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany === "parasSolutions") {
      setSelectedClient("iifl");
      setSelectedBankDetails("parasSolutionsBank");
    }
  }, [selectedCompany]);

  return (
    <>
      <div>
        <div className="flex items-center p-5 fixed left-0 top-0 w-full justify-start flex-wrap bg-[#e9ecef]">
          <div className="mr-5">
            <EditableCalender
              dueDateText={dueDateText}
              setDueDateText={setDueDateText}
              selectedCompany={selectedCompany}
              showDateBox={showDateBox}
              setShowDateBox={setShowDateBox}
              handleDateBox={handleDateBox}
              setShowCalculateBox={setShowCalculateBox}
              invoiceNo={invoiceNo}
              setInvoiceNo={setInvoiceNo}
              invoiceDate={invoiceDate}
              setInvoiceDate={setInvoiceDate}
              challan={challan}
              setChallan={setChallan}
              dueDate={dueDate}
              setDueDate={setDueDate}
              invoiceNoLabel={invoiceNoLabel}
              setInvoiceNoLabel={setInvoiceNoLabel}
              invoiceDateLabel={invoiceDateLabel}
              setInvoiceDateLabel={setInvoiceDateLabel}
              challanLabel={challanLabel}
              setChallanLabel={setChallanLabel}
              dueDateLabel={dueDateLabel}
              setDueDateLabel={setDueDateLabel}
              formattedInvoiceDate={formattedInvoiceDate}
              handleInvoiceDateChange={handleInvoiceDateChange}
              handleDueDateChange={handleDueDateChange}
            />
          </div>
          <div className="edit-date--component">
            <EditDescription
              selectedCompany={selectedCompany}
              selectedClient={selectedClient}
              description={description}
              setDescription={setDescription}
              setSelectDescription={setSelectDescription}
              selectDescription={selectDescription}
              handleCalculateBox={handleCalculateBox}
              showCalculateBox={showCalculateBox}
              setShowCalculateBox={setShowCalculateBox}
              isEditing={isEditing}
              qty={qty}
              setQty={setQty}
              unitPrice={unitPrice}
              setUnitPrice={setUnitPrice}
              selectUnitPrice={selectUnitPrice}
              setSelectUnitPrice={setSelectUnitPrice}
              setDesItem={setDesItem}
              total={total}
              setTotal={setTotal}
              labels={labels}
              setLabels={setLabels}
              sgst={sgst}
              setSgst={setSgst}
              cgst={cgst}
              setCgst={setCgst}
              totalGst={totalGst}
              setTotalGst={setTotalGst}
              totalAmount={totalAmount}
              setTotalAmount={setTotalAmount}
              handleDescriptionRow={handleDescriptionRow}
              inWords={inWords}
              setInWords={setInWords}
              finalAmountAfterGst={finalAmountAfterGst}
              selectHsnCode={selectHsnCode}
              setSelectHsnCode={setSelectHsnCode}
            />
          </div>
        </div>

        {/* Main invoice */}
        <div className="border m-14 mt-32">
          <main className="app" ref={componentRef}>
            <CompanyDetails
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
            />
            <ClientDetails
              invoiceNo={invoiceNo}
              invoiceDate={invoiceDate}
              challan={challan}
              dueDate={dueDate}
              invoiceNoLabel={invoiceNoLabel}
              invoiceDateLabel={invoiceDateLabel}
              challanLabel={challanLabel}
              dueDateLabel={dueDateLabel}
              formattedInvoiceDate={formattedInvoiceDate}
              formattedDueDate={formattedDueDate}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              dueDateText={dueDateText}
              selectedCompany={selectedCompany}
            />
            <DescriptionHead />
            {selectedCompany === "parasPrint" && (
              <HrDescription selectedCompany={selectedCompany} />
            )}
            <Description
              desItem={desItem}
              handleDescriptionRow={handleDescriptionRow}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
            {selectedCompany === "parasSolutions" ? <TdsLine /> : ""}
            <AmountDetails
              total={total}
              labels={labels}
              setLabels={setLabels}
              sgst={sgst}
              setSgst={setSgst}
              cgst={cgst}
              setCgst={setCgst}
              fuelSurcharge={fuelSurcharge}
              setFuelSurcharge={setFuelSurcharge}
              totalGst={totalGst}
              setTotalGst={setTotalGst}
              totalAmount={totalAmount}
              setTotalAmount={setTotalAmount}
              handleDescriptionRow={handleDescriptionRow}
              inWords={inWords}
              setInWords={setInWords}
              finalAmountAfterGst={finalAmountAfterGst}
              selectedCompany={selectedCompany}
              selectedBankDetails={selectedBankDetails}
              description={description}
              selectDescription={selectDescription}
              desItem={desItem}
              setSelectedBankDetails={setSelectedBankDetails}
            />
            <Footer />
          </main>
        </div>
      </div>
      <ReactToPrint
        trigger={() => (
          <button className="btn relative left-1/2 mb-10 rounded-full p-3 hover:bg-green-500">
            Download
          </button>
        )}
        content={() => componentRef.current}
      />
    </>
  );
}

export default App;
