import React, { useState } from "react";

export default function Footer() {
  const [declaration, setDeclaration] = useState(
    "We declare that this Invoice shows the actual price of the Goods described and that all particulars are true and correct."
  );

  return (
    <>
      {/*  ------------------------------------ section 3 */}
      <div className="flex mx-8 mb-8 border-x border-b border-black text-sm text-center">
        <div className="text-start p-2 border-r border-black w-[59.9%]">
          <div>
            <p>Declaration:</p>
          </div>
          <div className="">
            <textarea
              style={{ resize: "none" }}
              className="py-2 font-medium"
              rows={3}
              cols={50}
              placeholder="declaration...."
              value={declaration}
              onChange={(e) => setDeclaration(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-grow items-end justify-center text-center">
          <p></p>
          <p className="author-sign">Authorized Signatory</p>
        </div>
      </div>
    </>
  );
}
