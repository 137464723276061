import React from "react";
import { parasPrintBankDetails, parasSolutionsBankDetails } from "../constant";

const BankDetails = ({ bank }) => {
  return (
    <div className="text-sm font-medium border-x border-black w-[60%]">
      <div className="flex p-1 pl-2 pt-2">
        <p className="mr-8">BANK NAME:</p>
        <p className="tracking-wide">{bank.bankName}</p>
      </div>
      <div className="flex p-1 pl-2">
        <p className="mr-5">ACCOUNT NO:</p>
        <p className="tracking-wide">{bank.accNo}</p>
      </div>
      <div className="flex p-1 pl-2">
        <p className="mr-11">IFSC CODE:</p>
        <p className="tracking-wide">{bank.ifsc}</p>
      </div>
      <div className="flex p-1 pl-2">
        <p className="mr-14">BRANCH:</p>
        <p className="tracking-wide">{bank.branch}</p>
      </div>
    </div>
  );
};

export default function AmountDetails({
  sgst,
  cgst,
  totalGst,
  totalAmount,
  labels,
  finalAmountAfterGst,
  inWords,
  selectedCompany,
  selectedBankDetails,
  setSelectedBankDetails,
  fuelSurcharge,
  desItem,
}) {
  const SelectBankDetails =
    selectedCompany === "parasPrint"
      ? parasPrintBankDetails
      : parasSolutionsBankDetails;

  const handleChange = (e) => {
    setSelectedBankDetails(e.target.value);
  };

  const {
    totalAmountBeforeGSTLabel,
    sgstLabel,
    cgstLabel,
    fuelSurchargeLabel,
    totalGstLabel,
    totalAmountAfterGSTLabel,
    totalAmountInWordsLabel,
  } = labels;

  return (
    <>
      <section>
        <div className="flex ml-8 mr-8 border-r border-t text-sm text-center border-black">
          <div className="text-start border-l border-r border-black p-2 w-[60%]">
            <p className="">
              {totalAmountInWordsLabel
                ? totalAmountInWordsLabel
                : "Amount In Words:"}
            </p>
            <p className="">
              <strong>{inWords ? inWords : "-"}</strong>
            </p>
          </div>
          <div className="font-medium border-r border-black p-2 pt-5 w-[22%]">
            <p>
              {totalAmountBeforeGSTLabel ? totalAmountBeforeGSTLabel : "Total:"}
            </p>
          </div>
          <div className="flex-grow p-2 pt-5 font-medium border-black">
            <p>{totalAmount ? totalAmount : 0}</p>
          </div>
        </div>

        <div className="ml-8 mr-8 flex border-x border-t border-black text-sm text-center font-medium">
          <div className="border-r border-black p-3 text-start w-[59.9%]">
            <select
              className="appearance-none hover:appearance-auto rounded pr-2 focus:border focus:bg-slate-200 focus:border-black leading-tight focus:outline-none hover:bg-slate-200"
              value={selectedBankDetails}
              onChange={handleChange}
            >
              <option value="parasPrintBank">PARAS PRINT BANK DETAILS</option>
              <option value="parasSolutionsBank">
                PARAS SOLUTIONS BANK DETAILS
              </option>
            </select>
          </div>
          <div className="border-r border-black w-[22%] p-3">
            <p>{cgstLabel ? cgstLabel : "Total:"}</p>
          </div>
          <div className="flex-grow p-3">
            <p>{cgst ? cgst : 0}</p>
          </div>
        </div>

        <div className="flex ml-8 mr-8 border-r border-t border-b border-black">
          <BankDetails bank={SelectBankDetails} />

          <div className="text-sm font-medium border-r border-black w-[22%] text-center">
            <div>
              <div className="p-2 pt-4 border-b border-black">
                <p>{sgstLabel ? sgstLabel : "SGST (9%):"}</p>
              </div>
              {selectedCompany === "parasPrint" &&
              desItem[0]?.selectDescription === "Courier Charges" ? (
                <div className="p-3 border-b border-black">
                  <p>
                    {fuelSurchargeLabel
                      ? fuelSurchargeLabel
                      : "Fuel Surcharge (10%)"}
                  </p>
                </div>
              ) : (
                <div className="p-3 border-b border-black">
                  <p>{totalGstLabel ? totalGstLabel : "Total Amount: GST"}</p>
                </div>
              )}
              <div className="p-2 pt-3">
                <p>
                  {totalAmountAfterGSTLabel
                    ? "Total Amount"
                    : totalAmountAfterGSTLabel}
                </p>
              </div>
            </div>
          </div>

          <div className="text-sm font-medium w-[18.1%] text-center">
            <div>
              <div className="p-2 pt-4 border-b border-black">
                <p>{sgst ? sgst : 0}</p>
              </div>
              {selectedCompany === "parasSolutions" && (
                <div className="p-3 border-b border-black">
                  <p>{totalGst ? totalGst : 0}</p>
                </div>
              )}
              {selectedCompany === "parasPrint" &&
                desItem[0]?.selectDescription === "ID Card" && (
                  <div className="p-3 border-b border-black">
                    <p>{totalGst ? totalGst : 0}</p>
                  </div>
                )}
              {selectedCompany === "parasPrint" &&
                desItem[0]?.selectDescription === "Courier Charges" && (
                  <div className="p-3 border-b border-black">
                    <p>{fuelSurcharge ? fuelSurcharge : 0}</p>
                  </div>
                )}
              <div className="p-2 pt-3">
                <p>{finalAmountAfterGst ? finalAmountAfterGst : 0}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
