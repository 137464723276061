import React, { useEffect, useRef, useState } from "react";

export default function EditDescription({
  qty,
  setQty,
  unitPrice,
  setUnitPrice,
  total,
  setTotal,
  sgst,
  cgst,
  totalGst,
  totalAmount,
  handleDescriptionRow,
  labels,
  setLabels,
  finalAmountAfterGst,
  inWords,
  setSelectHsnCode,
  selectHsnCode,
  showCalculateBox,
  handleCalculateBox,
  selectDescription,
  setSelectDescription,
  description,
  setDescription,
  setShowCalculateBox,
  setSelectUnitPrice,
  selectedClient,
  selectUnitPrice,
  selectedCompany,
}) {
  const {
    descriptionLabel,
    hsnCodeLabel,
    qtyLabel,
    unitPriceLabel,
    totalLabel,
    totalAmountBeforeGSTLabel,
    sgstLabel,
    cgstLabel,
    totalGstLabel,
    totalAmountAfterGSTLabel,
    totalAmountInWordsLabel,
  } = labels;

  const [showInfo, setShowInfo] = useState(false);

  let desModalRef = useRef();

  useEffect(() => {
    let handleWindowClick = (e) => {
      if (!desModalRef.current.contains(e.target)) {
        setShowCalculateBox(false);
      }
    };

    document.addEventListener("mousedown", handleWindowClick);

    return () => {
      document.removeEventListener("mousedown", handleWindowClick);
    };
  });

  function handleInfo() {
    setShowInfo(!showInfo);
  }

  return (
    <div ref={desModalRef}>
      <button className="btn" onClick={() => handleCalculateBox()}>
        Edit Description
      </button>
      {/* ---------------- for parasSolutions -------------------------- */}
      {showCalculateBox && (
        <div className="edit-window w-[80%]">
          {selectedCompany === "parasSolutions" ? (
            <div className="description">
              {/* description */}

              <div>
                <input
                  className="input-label"
                  disabled
                  value={descriptionLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, descriptionLabel: e.target.value })
                  }
                />
                <select
                  className="select width-margin"
                  value={selectDescription}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (selectedClient === "iifl") {
                      if (value === "Post Reimbursement Charges") {
                        setSelectDescription("Post Reimbursement Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(22.5);
                      } else if (value === "Printing & Dispatch Charges") {
                        setSelectDescription("Printing & Dispatch Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(4.5);
                      } else if (value === "SMS Charges") {
                        setSelectDescription("SMS Charges");
                        setSelectHsnCode("998413");
                        setSelectUnitPrice(2.25);
                      } else if (value === "Rounding off") {
                        setSelectDescription("Rounding off");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      } else if (value === "Edit") {
                        setSelectDescription("Edit");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      } else {
                        setSelectDescription("Post Reimbursement Charges");
                        setSelectHsnCode(value);
                        setSelectUnitPrice(0);
                      }
                    } else if (selectedClient === "capri") {
                      if (value === "Post Reimbursement Charges") {
                        setSelectDescription("Post Reimbursement Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(25);
                      } else if (value === "Printing & Dispatch Charges") {
                        setSelectDescription("Printing & Dispatch Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(5.4);
                      } else if (value === "Rounding off") {
                        setSelectDescription("Rounding off");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      } else if (value === "Edit") {
                        setSelectDescription("Edit");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      }
                    } else if (
                      selectedClient === "capitalIndia" ||
                      selectedClient === "capitalIndiaFinance"
                    ) {
                      if (value === "Post Reimbursement Charges") {
                        setSelectDescription("Post Reimbursement Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(2.5);
                      } else if (value === "Printing & Dispatch Charges") {
                        setSelectDescription("Printing & Dispatch Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(6);
                      } else if (value === "Rounding off") {
                        setSelectDescription("Rounding off");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      } else if (value === "Edit") {
                        setSelectDescription("Edit");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      }
                    }
                  }}
                >
                  {selectedClient === "iifl" && (
                    <>
                      <option value="Select Description">
                        Select Description
                      </option>
                      <option value="Post Reimbursement Charges">
                        Post Reimbursement Charges
                      </option>
                      <option value="Printing & Dispatch Charges">
                        Printing & Dispatch Charges
                      </option>
                      <option value="SMS Charges">SMS Charges</option>
                      <option value="Rounding off">Rounding off</option>
                      <option value="Edit">Edit</option>
                    </>
                  )}
                  {selectedClient === "capri" && (
                    <>
                      <option value="Select Description">
                        Select Description
                      </option>
                      <option value="Post Reimbursement Charges">
                        Post Reimbursement Charges
                      </option>
                      <option value="Printing & Dispatch Charges">
                        Printing & Dispatch Charges
                      </option>
                      <option value="Rounding off">Rounding off</option>
                      <option value="Edit">Edit</option>
                    </>
                  )}
                  {selectedClient === "capitalIndia" && (
                    <>
                      <option value="Select Description">
                        Select Description
                      </option>
                      <option value="Post Reimbursement Charges">
                        Post Reimbursement Charges
                      </option>
                      <option value="Printing & Dispatch Charges">
                        Printing & Dispatch Charges
                      </option>
                      <option value="Rounding off">Rounding off</option>
                      <option value="Edit">Edit</option>
                    </>
                  )}
                  {selectedClient === "capitalIndiaFinance" && (
                    <>
                      <option value="Select Description">
                        Select Description
                      </option>
                      <option value="Post Reimbursement Charges">
                        Post Reimbursement Charges
                      </option>
                      <option value="Printing & Dispatch Charges">
                        Printing & Dispatch Charges
                      </option>
                      <option value="Rounding off">Rounding off</option>
                      <option value="Edit">Edit</option>
                    </>
                  )}
                </select>
                {selectDescription === "Edit" ? (
                  <textarea
                    className="select width-margin"
                    style={{ resize: "none" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    readOnly={false}
                  />
                ) : (
                  ""
                )}
              </div>

              {/* hsnCode */}
              <div>
                <input
                  className="input-label"
                  disabled
                  value={hsnCodeLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, hsnCodeLabel: e.target.value })
                  }
                />
                <select
                  className="select width-margin"
                  value={selectHsnCode}
                  onChange={(e) => setSelectHsnCode(e.target.value)}
                >
                  <option value="-">-</option>
                  <option value="998912">998912</option>
                  <option value="998413">998413</option>
                </select>
              </div>
              {/* qty */}
              <div>
                <input
                  className="input-label"
                  disabled
                  value={qtyLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, qtyLabel: e.target.value })
                  }
                />
                <input
                  className="select width-margin"
                  type="number"
                  value={qty}
                  onChange={(e) => setQty(e.target.value)}
                />
              </div>
              {/* unitPrice */}
              <div>
                <input
                  className="input-label"
                  disabled
                  value={unitPriceLabel}
                  onChange={(e) =>
                    setLabels({
                      ...labels,
                      unitPriceLabel: e.target.value,
                    })
                  }
                />
                <select
                  className="select width-margin"
                  value={selectUnitPrice}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "Edit") {
                      setSelectUnitPrice("Edit");
                    } else {
                      setSelectUnitPrice(parseFloat(value));
                    }
                  }}
                >
                  <option value={0}>0</option>
                  {selectedClient === "iifl" && (
                    <>
                      <option value={22.5}>22.50</option>
                      <option value={2.25}>2.25</option>
                      <option value={4.5}>4.50</option>
                    </>
                  )}
                  {selectedClient === "capri" && (
                    <>
                      <option value={25}>25.00</option>
                      <option value={5.4}>5.40</option>
                    </>
                  )}
                  {(selectedClient === "capitalIndia" ||
                    selectedClient === "capitalIndiaFinance") && (
                    <>
                      <option value={2.5}>2.50</option>
                      <option value={6.0}>6.00</option>
                    </>
                  )}
                  <option value="Edit">Edit</option>
                </select>
                {selectUnitPrice === "Edit" ? (
                  <input
                    className="select width-margin"
                    value={unitPrice}
                    type="number"
                    onChange={(e) => setUnitPrice(e.target.value)}
                    readOnly={false}
                  />
                ) : (
                  ""
                )}
              </div>
              {/* total */}
              <div>
                <input
                  className="input-label"
                  disabled
                  value={totalLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, totalLabel: e.target.value })
                  }
                />

                <input
                  className="select width-margin"
                  readOnly
                  value={total}
                  type="number"
                />
              </div>
            </div>
          ) : (
            <div className="description">
              {/* ---------------- for paras print -------------------------- */}
              {/* description */}
              <div>
                <input
                  className="input-label"
                  style={{ width: "150px" }}
                  disabled
                  value={descriptionLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, descriptionLabel: e.target.value })
                  }
                />
                <select
                  className="select width-margin"
                  value={selectDescription}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (selectedClient === "piramalEnterprises") {
                      if (value === "Courier Charges") {
                        setSelectDescription("Courier Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice("-");
                      } else if (value === "ID Card") {
                        setSelectDescription("ID Card");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(25);
                      } else if (value === "Edit") {
                        setSelectDescription("Edit");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      } else {
                        setSelectDescription("Courier Charges");
                        setSelectHsnCode(value);
                        setSelectUnitPrice("-");
                      }
                    } else if (selectedClient === "piramalCapitalAndHousing") {
                      if (value === "Courier Charges") {
                        setSelectDescription("Courier Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice("-");
                      } else if (value === "ID Card") {
                        setSelectDescription("ID Card");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(25);
                      } else if (value === "Edit") {
                        setSelectDescription("Edit");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      } else {
                        setSelectDescription("Courier Charges");
                        setSelectHsnCode(value);
                        setSelectUnitPrice("-");
                      }
                    } else if (selectedClient === "piramalSalesAndServices") {
                      if (value === "Courier Charges") {
                        setSelectDescription("Courier Charges");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice("-");
                      } else if (value === "ID Card") {
                        setSelectDescription("ID Card");
                        setSelectHsnCode("998912");
                        setSelectUnitPrice(25);
                      } else if (value === "Edit") {
                        setSelectDescription("Edit");
                        setSelectHsnCode("-");
                        setSelectUnitPrice("Edit");
                      } else {
                        setSelectDescription("Courier Charges");
                        setSelectHsnCode(value);
                        setSelectUnitPrice("-");
                      }
                    }
                  }}
                >
                  {selectedClient === "piramalEnterprises" && (
                    <>
                      <option value="Select Description">
                        Select Description
                      </option>
                      <option value="Courier Charges">Courier Charges</option>
                      <option value="ID Card">ID Card</option>
                      <option value="Edit">Edit</option>
                    </>
                  )}
                  {selectedClient === "piramalCapitalAndHousing" && (
                    <>
                      <option value="Select Description">
                        Select Description
                      </option>
                      <option value="Courier Charges">Courier Charges</option>
                      <option value="ID Card">ID Card</option>
                      <option value="Edit">Edit</option>
                    </>
                  )}
                  {selectedClient === "piramalSalesAndServices" && (
                    <>
                      <option value="Select Description">
                        Select Description
                      </option>
                      <option value="Courier Charges">Courier Charges</option>
                      <option value="ID Card">ID Card</option>
                      <option value="Edit">Edit</option>
                    </>
                  )}
                </select>
                {selectDescription === "Edit" ? (
                  <textarea
                    className="select width-margin"
                    style={{ width: "80%" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    readOnly={false}
                  />
                ) : (
                  ""
                )}
              </div>
              {/* hsnCode */}
              <div>
                <input
                  className="input-label"
                  disabled
                  style={{ width: "100px" }}
                  value={hsnCodeLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, hsnCodeLabel: e.target.value })
                  }
                />
                <select
                  className="select width-margin"
                  value={selectHsnCode}
                  onChange={(e) => setSelectHsnCode(e.target.value)}
                >
                  <option value="-">-</option>
                  <option value="998912">998912</option>
                  <option value="998413">998413</option>
                </select>
              </div>
              {/* qty */}
              <div>
                <input
                  className="input-label"
                  disabled
                  value={qtyLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, qtyLabel: e.target.value })
                  }
                />
                <input
                  className="select width-margin"
                  type="number"
                  value={qty}
                  onChange={(e) => setQty(e.target.value)}
                />
              </div>
              {/* unitPrice */}
              <div>
                <input
                  className="input-label"
                  disabled
                  value={unitPriceLabel}
                  onChange={(e) =>
                    setLabels({
                      ...labels,
                      unitPriceLabel: e.target.value,
                    })
                  }
                />
                <select
                  className="select width-margin"
                  value={selectUnitPrice}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "Edit") {
                      setSelectUnitPrice("Edit");
                    } else {
                      setSelectUnitPrice(parseFloat(value));
                    }
                  }}
                >
                  <option value={0}>0</option>
                  {selectedClient === "iifl" && (
                    <>
                      <option value={22.5}>22.50</option>
                      <option value={2.25}>2.25</option>
                      <option value={4.5}>4.50</option>
                    </>
                  )}
                  {selectedClient === "capri" && (
                    <>
                      <option value={25}>25.00</option>
                      <option value={5.4}>5.40</option>
                    </>
                  )}
                  {selectedClient === "capitalIndia" && (
                    <>
                      <option value={2.5}>2.50</option>
                      <option value={6.0}>6.00</option>
                    </>
                  )}
                  {selectedClient === "piramalEnterprises" && (
                    <>
                      <option value={"-"}>-</option>
                      <option value={25}>25.00</option>
                      <option value={50}>50.00</option>
                    </>
                  )}
                  {selectedClient === "piramalCapitalAndHousing" && (
                    <>
                      <option value={"-"}>-</option>
                      <option value={25}>25.00</option>
                      <option value={50}>50.00</option>
                    </>
                  )}
                  {selectedClient === "piramalSalesAndServices" && (
                    <>
                      <option value={"-"}>-</option>
                      <option value={25}>25.00</option>
                      <option value={50}>50.00</option>
                    </>
                  )}
                  <option value="Edit">Edit</option>
                </select>
                {selectUnitPrice === "Edit" ? (
                  <input
                    className="select width-margin"
                    value={unitPrice}
                    type="number"
                    onChange={(e) => setUnitPrice(e.target.value)}
                    readOnly={false}
                  />
                ) : (
                  ""
                )}
              </div>
              {/* total */}
              <div>
                <input
                  className="input-label"
                  disabled
                  value={totalLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, totalLabel: e.target.value })
                  }
                />

                {!selectDescription === "Courier Charges" ? (
                  <input
                    className="select width-margin"
                    readOnly
                    value={total}
                    type="number"
                  />
                ) : (
                  <input
                    className="select width-margin"
                    value={isNaN(total) ? "" : total}
                    onChange={(e) => {
                      const inputValue = parseFloat(e.target.value);
                      if (!isNaN(inputValue)) {
                        setTotal(inputValue);
                      } else {
                        setTotal("");
                      }
                    }}
                    type="number"
                  />
                )}
              </div>
            </div>
          )}
          {/* ------------------------------------------------------------------------------ */}
          {/* total amount before tax */}
          {showInfo && (
            <div className="info-window">
              <div className="flex">
                <input
                  className="input-label"
                  disabled
                  value={totalAmountBeforeGSTLabel}
                  onChange={(e) =>
                    setLabels({
                      ...labels,
                      totalAmountBeforeGSTLabel: e.target.value,
                    })
                  }
                />
                <input
                  className="select-info width-margin ml-24"
                  readOnly
                  value={totalAmount}
                  type="number"
                />
              </div>
              <div className="flex">
                <input
                  className="input-label"
                  value={cgstLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, cgstLabel: e.target.value })
                  }
                />
                <input
                  className="select-info width-margin ml-24"
                  readOnly
                  value={cgst}
                  type="number"
                />
              </div>
              <div className="flex">
                <input
                  className="input-label"
                  value={sgstLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, sgstLabel: e.target.value })
                  }
                />
                <input
                  className="select-info width-margin ml-24"
                  readOnly
                  value={sgst}
                  type="number"
                />
              </div>
              <div>
                <input
                  className="input-label w-[12%]"
                  disabled
                  value={totalGstLabel}
                  onChange={(e) =>
                    setLabels({ ...labels, totalGstLabel: e.target.value })
                  }
                />
                <input
                  className="select-info width-margin ml-16"
                  readOnly
                  value={totalGst}
                  type="number"
                />
                {}
              </div>
              <div>
                <input
                  className="input-label w-[15%]"
                  disabled
                  value={totalAmountAfterGSTLabel}
                  onChange={(e) =>
                    setLabels({
                      ...labels,
                      totalAmountAfterGSTLabel: e.target.value,
                    })
                  }
                />
                <input
                  className="select-info width-margin ml-7"
                  readOnly
                  value={finalAmountAfterGst}
                  type="number"
                />
              </div>
              <div className="flex flex-wrap mt-2">
                <input
                  className="input-label w-[21%]"
                  disabled
                  value={totalAmountInWordsLabel}
                  onChange={(e) =>
                    setLabels({
                      ...labels,
                      totalAmountInWordsLabel: e.target.value,
                    })
                  }
                />
                <input
                  className="select-info my-2"
                  readOnly
                  value={inWords}
                  type="text"
                />
              </div>
            </div>
          )}
          <button
            disabled={!qty}
            className={`btn bg-[#119da4] text-[#343a40] font-bold text-sm mr-6 mt-8 ${
              qty ? "" : "cursor-not-allowed"
            }`}
            onClick={handleDescriptionRow}
          >
            ADD ROW
          </button>
          <button
            className="btn text-sm bg-[#ff6700] text-[#343a40] font-bold"
            onClick={handleInfo}
          >
            {showInfo ? "HIDE INFO" : "SHOW INFO"}
          </button>
        </div>
      )}
    </div>
  );
}
