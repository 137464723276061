import React from "react";
import {
  capitalIndiaAddress,
  capitalIndiaAddress2,
  capriAddress,
  iiflAddress,
  piramalCapitalAndHousingAddress,
  piramalEnterprisesAddress,
  piramalSalesAndServicesAddress,
  selectAddress,
} from "../constant";

const clients = {
  selectAdd: selectAddress,
  iifl: iiflAddress,
  capri: capriAddress,
  capitalIndia: capitalIndiaAddress,
  capitalIndiaFinance: capitalIndiaAddress2,
  piramalEnterprises: piramalEnterprisesAddress,
  piramalCapitalAndHousing: piramalCapitalAndHousingAddress,
  piramalSalesAndServices: piramalSalesAndServicesAddress,
};

export default function ClientDetails({
  invoiceNo,
  challan,
  invoiceNoLabel,
  invoiceDateLabel,
  challanLabel,
  dueDateLabel,
  formattedInvoiceDate,
  formattedDueDate,
  selectedClient,
  selectedCompany,
  dueDateText,
  setSelectedClient,
}) {
  const selectedClientDetails = clients[selectedClient];

  const { clientCompanyName, ...details } = selectedClientDetails;

  return (
    <section className="flex ml-8 mr-8">
      <div className="w-[70%] border-l text-sm border-r border-b border-black">
        <div className="p-4">
          <select
            className="appearance-none hover:appearance-auto rounded text-lg pb-1 pr-2 focus:border focus:bg-slate-200 focus:border-black font-semibold leading-tight focus:outline-none hover:bg-slate-200"
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
          >
            {selectedCompany === "parasSolutions" && (
              <>
                <option value="iifl">IIFL FINANCE LTD</option>
                <option value="capri">CAPRI GLOBAL CAPITAL LIMITED</option>
                <option value="capitalIndia">
                  CAPITAL INDIA HOME LOANS LIMITED
                </option>
                <option value="capitalIndiaFinance">
                  CAPITAL INDIA FINANCE LIMITED
                </option>
              </>
            )}
            {selectedCompany === "parasPrint" && (
              <>
                <option value="piramalEnterprises">
                  PIRAMAL ENTERPRISES LIMITED
                </option>
                <option value="piramalCapitalAndHousing">
                  PIRAMAL CAPITAL AND HOUSING FINANCE LIMITED
                </option>
                <option value="piramalSalesAndServices">
                  PIRAMAL FINANCE SALES AND SERVICES PRIVATE LIMITED
                </option>
              </>
            )}
          </select>
          {Object.values(details).map((detail, index) => (
            <p key={index} className="text-sm">
              {detail}
            </p>
          ))}
        </div>
      </div>
      <div className="w-[30%] border-r border-b border-black">
        <div className="">
          <div className="flex border-b p-3 border-black">
            <p className="text-sm">
              {invoiceNoLabel ? invoiceNoLabel : "Invoice-No:"}
            </p>
            <span className="text-sm pl-3">
              <strong>{invoiceNo ? invoiceNo : "-"}</strong>
            </span>
          </div>
          <div className="flex border-b p-3 border-black">
            <p className="text-sm">
              {invoiceDateLabel ? invoiceDateLabel : "Invoice-Date:"}
            </p>
            <span className="text-sm pl-1">
              <strong>
                {formattedInvoiceDate ? formattedInvoiceDate : "-"}
              </strong>
            </span>
          </div>
          <div className="flex border-b p-3 border-black">
            <p className="text-sm">
              {challanLabel ? challanLabel : "CHALLAN:"}
            </p>
            <span className="text-sm pl-4">
              <strong>{challan ? challan : "-"}</strong>
            </span>
          </div>
          <div className="flex p-3">
            <p className="text-sm">
              {dueDateLabel ? dueDateLabel : "Due-Date:"}
            </p>
            <span className="text-sm pl-4">
              {selectedCompany === "parasSolutions" ? (
                <strong>{formattedDueDate ? formattedDueDate : "-"}</strong>
              ) : (
                <strong>{dueDateText}</strong>
              )}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
