import React from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

export default function Description({ desItem, onDelete, onEdit }) {
  return (
    <>
      {desItem.map((item, index) => (
        <section
          key={index}
          className="desrow flex ml-8 mr-8 text-sm text-center font-medium border-x border-black relative"
        >
          <div className="text-start border-r border-black p-2 w-[50%]">
            {item.selectDescription === "Edit"
              ? item.description
              : item.selectDescription}
          </div>
          <div className="border-r border-black p-2 w-[10%]">
            {item.selectHsnCode}
          </div>
          <div className="border-r border-black p-2 w-[10%]">{item.qty}</div>
          <div className=" border-r border-black p-2 w-[12%]">
            {item.selectUnitPrice === "Edit"
              ? item.unitPrice
              : item.selectUnitPrice}
          </div>
          <div className="flex-grow p-2">{item.total}</div>
          <button className="btn-delete">
            <MdDelete onClick={() => onDelete(item.id)} id="delete" />
          </button>
          <button className="btn-delete">
            <CiEdit onClick={() => onEdit(item.id)} id="edit" />
          </button>
        </section>
      ))}
    </>
  );
}
