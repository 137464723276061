import React, { useState, useMemo } from "react";
import { constantCode, constantHrNames, constantMonth } from "../constant";

export default function HrDescription() {
  const today = useMemo(() => new Date(), []); // Memoize the current date
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const months = useMemo(() => Object.values(constantMonth), []); // Memoize constant arrays
  const hrNames = useMemo(() => Object.values(constantHrNames), []);
  const codes = useMemo(() => Object.values(constantCode), []);

  const [selectedMonth, setSelectedMonth] = useState(months[currentMonth]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedHrNames, setSelectedHrNames] = useState(
    constantHrNames.SaurabhDixit
  );
  const [selectedCode, setSelectedCode] = useState(constantCode.PFSS);

  const years = useMemo(() => {
    const startYear = 2015;
    const endYear = 2030;
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, i) => startYear + i
    );
  }, []); // Memoize the years array

  const handleSelectChange = (setStateFn) => (event) => {
    setStateFn(event.target.value);
  }; // Generalized event handler

  return (
    <section className="ml-8 mr-8 border-x border-b border-black">
      <div className="flex text-center text-sm">
        <div className="p-2 border-r border-black w-[50%]">
          <div className="text-start">
            <span>Bill For The Month Of</span>
            <span>
              <select
                className="appearance-none rounded pl-1 leading-tight focus:outline-none hover:bg-slate-200"
                value={selectedMonth}
                onChange={handleSelectChange(setSelectedMonth)}
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </span>
            <span className="pr-2">-</span>
            <span>
              <select
                className="appearance-none rounded p-1 leading-tight focus:outline-none hover:bg-slate-200"
                value={selectedYear}
                onChange={handleSelectChange(setSelectedYear)}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <div className="text-start pl-14">
            <span className="font-medium">
              <select
                className="appearance-none rounded p-1 leading-tight focus:outline-none hover:bg-slate-200"
                value={selectedHrNames}
                onChange={handleSelectChange(setSelectedHrNames)}
              >
                {hrNames.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </span>
            <span> - </span>
            <span className="font-medium">
              <select
                className="appearance-none rounded p-1 leading-tight focus:outline-none hover:bg-slate-200"
                value={selectedCode}
                onChange={handleSelectChange(setSelectedCode)}
              >
                {codes.map((code) => (
                  <option key={code} value={code}>
                    {code}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
        <div className="border-r border-black w-[10%]"></div>
        <div className="border-r border-black w-[10%]"></div>
        <div className="border-r border-black w-[12%]"></div>
        <div className="flex-grow"></div>
      </div>
    </section>
  );
}
