import React from "react";

export default function DescriptionHead() {
  return (
    <section className="flex ml-8 mr-8 border-r border-b border-black text-sm text-center font-medium">
      <div className="border-l border-r border-black p-2 w-[50%]">
        <p>DESCRIPTION</p>
      </div>
      <div className="border-r border-black p-2 w-[10%]">
        <p>HSN CODE</p>
      </div>
      <div className="border-r border-black p-2 w-[10%]">
        <p>QTY</p>
      </div>
      <div className="border-r border-black p-2 w-[12%]">
        <p>UNIT PRICE</p>
      </div>
      <div className="flex-grow border-black p-2">
        <p>TOTAL</p>
      </div>
    </section>
  );
}
