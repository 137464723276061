import React, { useEffect, useRef } from "react";

export default function EditableCalender({
  invoiceNo,
  setInvoiceNo,
  invoiceDate,
  challan,
  setChallan,
  dueDate,
  invoiceNoLabel,
  setInvoiceNoLabel,
  invoiceDateLabel,
  setInvoiceDateLabel,
  challanLabel,
  setChallanLabel,
  dueDateLabel,
  setDueDateLabel,
  handleInvoiceDateChange,
  handleDueDateChange,
  handleDateBox,
  showDateBox,
  setShowDateBox,
  selectedCompany,
  dueDateText,
  setDueDateText,
}) {
  let calModalRef = useRef();

  useEffect(() => {
    let handleWindowClick = (e) => {
      if (!calModalRef.current.contains(e.target)) {
        setShowDateBox(false);
      }
    };

    document.addEventListener("mousedown", handleWindowClick);

    return () => {
      document.removeEventListener("mousedown", handleWindowClick);
    };
  });

  return (
    <div ref={calModalRef}>
      <button className="btn" onClick={handleDateBox}>
        Edit Date
      </button>
      {showDateBox && (
        <div className="edit-window">
          <div className="flex items-center justify-start p-2 border-b border-black">
            <input
              className="input-label"
              disabled
              value={invoiceNoLabel}
              onChange={setInvoiceNoLabel}
            />
            <input
              className="input"
              onChange={(e) => setInvoiceNo(e.target.value)}
              type="text"
              placeholder="08-2023/24"
              style={{ width: "60%" }}
              value={invoiceNo}
            />
          </div>
          <div className="flex items-center justify-start p-2 border-b border-black">
            <input
              className="input-label"
              disabled
              value={invoiceDateLabel}
              onChange={setInvoiceDateLabel}
            />
            <input
              className="input"
              onChange={handleInvoiceDateChange}
              type="date"
              name="begin"
              min="1990-01-01"
              max="2030-12-31"
              style={{ width: "60%" }}
              value={invoiceDate}
            />
          </div>
          <div className="flex items-center justify-start p-2 border-b border-black">
            <input
              className="input-label"
              value={challanLabel}
              onChange={setChallanLabel}
              disabled
            />
            <input
              className="input"
              onChange={(e) => setChallan(e.target.value)}
              type="number"
              placeholder="challan-no.."
              style={{ width: "60%" }}
              value={challan}
            />
          </div>
          <div className="flex items-center justify-start p-2">
            <input
              className="input-label"
              disabled
              value={dueDateLabel}
              onChange={setDueDateLabel}
            />
            {selectedCompany === "parasSolutions" ? (
              <input
                className="input-label"
                onChange={handleDueDateChange}
                type="date"
                name="begin"
                min="1990-01-01"
                max="2030-12-31"
                value={dueDate}
              />
            ) : (
              <input
                className="input"
                onChange={(e) => setDueDateText(e.target.value)}
                type="text"
                style={{ width: "60%" }}
                value={dueDateText}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
